/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import {Helmet} from 'react-helmet'

import "./layout.css"

const Layout = ({ children }) => (
  <section id='layout'>
    <Helmet>
      <meta name='description' content='Software engineer, tinkerer.' />
      <title>Jalada (David Somers)</title>
    </Helmet>
    {children}
  </section>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
